import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import HeadingTitle from '../../../component/HeadingTitle';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

export default function ModifiedCustomer() {
  const [refetch] = useOutletContext();
  const axiosPrivate = useAxiosPrivate();
  const params = useParams();
  const navigate = useNavigate();
  const id = params?.customerID;
  const [isLoading, setIsLoading] = useState(false);

  // ************___form state__****************//
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [checked, setChecked] = useState(false);
  const [remarks, setRemarks] = useState('');
  const [email, setEmail] = useState('');
  const [personalContact, setPersonalContact] = useState('');
  const [socialMedia, setSocialMedia] = useState('');
  const [contact, setContact] = useState('');
  const [commissionPercent, setCommissionPercent] = useState(0);
  const [dateOfStarting, setDateOfStarting] = useState(null);
  const [dateOfClosing, setDateOfClosing] = useState(null);
  const [description, setDescription] = useState('');
  const [customerType, setCustomerType] = useState('');
  const [customerTypeData, setCustomerTypeData] = useState([]);
  const [customerClass, setCustomerClass] = useState({});

  const [customerClassData, setCustomerClassData] = useState([]);
  const [territory, setTerritory] = useState({ name: '' });

  const [territoyData, setTerritoyData] = useState([]);

  const [isDateError1, setIsDateError1] = useState(false);
  const [isDateError2, setIsDateError2] = useState(false);

  // ************___form state__****************//

  // get edit Customers data
  useEffect(() => {
    setIsLoading(true);
    if (id) {
      axiosPrivate
        .get(`/api/v1/customer/${id}?populate=yes`)
        .then((res) => {
          setIsLoading(false);
          const data = res?.data?.data;

          setName(data?.name);
          setCode(data?.code);
          setChecked(data?.isActive);
          setRemarks(data?.remarks);
          setEmail(data?.email);
          setPersonalContact(data?.personalContact);
          setSocialMedia(data?.socialMedia);
          setContact(data?.contact);
          setCommissionPercent(data?.commissionPercent);
          setDateOfStarting(data?.dateOfStarting);
          setDateOfClosing(data?.dateOfClosing);
          setDescription(data?.description);
          setCustomerType({
            customerType: data?.customerType?.customerType,
            territoryType: data?.customerType?.territoryType,
            rank: data?.customerType?.rank,
          });
          setCustomerClass({
            customerClass: data?.customerClass?.customerClass,
          });
          setTerritory(data?.territory);
        })
        .catch((error) => console.warn(error));
    } else {
      setIsLoading(false);
    }
  }, [axiosPrivate, id]);

  // get Customer Class
  useEffect(() => {
    axiosPrivate
      .get('/api/v1/customer/class?isActive=true')
      .then((res) => {
        setCustomerClassData(res?.data?.data);
      })
      .catch((error) => console.warn(error));
  }, []);
  // get Customer Class

  // get customer type data
  useEffect(() => {
    axiosPrivate
      .get('/api/v1/customer/type?populate=yes&sort=rank&isActive=true')
      .then((res) => {
        setCustomerTypeData(res?.data?.data);
      })
      .catch((error) => console.warn(error));
  }, []);

  // get All territory data
  useEffect(() => {
    axiosPrivate
      .get(
        `/api/v1/territory?populate=yes&limit=none&isActive=true&territoryType=${customerType?.territoryType?.id}`,
      )
      .then((res) => {
        setTerritoyData(res?.data?.data);
      })
      .catch((error) => console.warn(error));
  }, [customerType?.territoryType?.id]);

  // get social media info
  const socialinfo = [
    'Whatsapp',
    'IMO',
    'Viber',
    'Telegram',
    'Button Phone',
    'Email',
    'Whatsapp-IMO',
    'Whatsapp-IMO-Viber',
  ];

  // get remarks info
  const remarksInfo = [
    'PH OFF',
    'NOT RECEIVING',
    'WRONG NUMBER',
    'IGNORING',
    'NO DATA',
    'CALL LATER',
  ];

  const handleSubmit = () => {
    if (territory?.name === undefined) {
      toast.warn('territory undefined!');
    } else {
      axiosPrivate({
        method: id ? 'patch' : 'post',
        url: id ? `/api/v1/customer/${id}` : '/api/v1/customer',
        data: {
          name,
          code,
          customerType: customerType.id,
          customerClass: customerClass.id,
          territory: territory.id,
          isActive: checked,
          remarks,
          commissionPercent,
          dateOfStarting,
          dateOfClosing,
          description,
          email,
          personalContact,
          socialMedia,
          contact,
        },
      })
        .then((res) => {
          toast.success(res.data.message);
          navigate('/customers');
          refetch();
        })
        .catch((error) => {
          toast.warn(error.response.data.message);
        });
    }
  };

  return (
    <Box bgcolor="white.main" p={3} borderRadius={2}>
      <HeadingTitle>{id ? 'Edit' : 'Add'} Customer</HeadingTitle>
      {isLoading ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ height: 'calc(100vh - 16rem)' }}>
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start">
                <Typography fontWeight="bold">Customer Status:</Typography>
                &emsp;
                <FormControlLabel
                  label={checked ? 'Active' : 'Inactive'}
                  control={
                    <Switch
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                    />
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <HeadingTitle variant={1}>Basic Info</HeadingTitle>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                size="small"
                type="text"
                label="Name"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                size="small"
                type="text"
                label="Code"
                fullWidth
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Remarks</InputLabel>
                <Select
                  value={remarks}
                  label="Remarks"
                  onChange={(e) => setRemarks(e.target.value)}>
                  {remarksInfo.map((info, index) => (
                    <MenuItem key={index} value={info}>
                      {info}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <Autocomplete
                size="small"
                fullWidth
                options={customerTypeData}
                getOptionLabel={(option) => option.customerType || ''}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(event, newValue) => {
                  setCustomerType(newValue);
                  setTerritory({ name: '' });
                }}
                value={customerType}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    fullWidth
                    label="Customer Type"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Autocomplete
                disabled={!id && territoyData < 1}
                size="small"
                fullWidth
                options={territoyData}
                getOptionLabel={(option) =>
                  option.parentTerritory
                    ? `${option?.parentTerritory?.name} - ${option?.name}`
                    : `${option?.name}`
                }
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(event, newValue) => {
                  setTerritory(newValue);
                }}
                value={territory}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    fullWidth
                    helperText={
                      customerType
                        ? `${customerType?.territoryType?.territoryType}`
                        : ''
                    }
                    label="Terrirtory"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Autocomplete
                disabled={!id && customerType < 1}
                size="small"
                fullWidth
                options={customerClassData}
                getOptionLabel={(option) => option.customerClass || ''}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(event, newValue) => {
                  setCustomerClass(newValue);
                }}
                value={customerClass}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    fullWidth
                    label="Customer Class"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Starting Date"
                  disableFuture
                  inputFormat="DD/MM/YYYY"
                  value={dateOfStarting}
                  onError={(reason) => setIsDateError1(Boolean(reason))}
                  onChange={(newValue) => setDateOfStarting(newValue)}
                  renderInput={(params) => (
                    <TextField size="small" {...params} fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Closing Date"
                  disableFuture
                  inputFormat="DD/MM/YYYY"
                  value={dateOfClosing}
                  onError={(reason) => setIsDateError2(Boolean(reason))}
                  onChange={(newValue) => setDateOfClosing(newValue)}
                  renderInput={(params) => (
                    <TextField size="small" {...params} fullWidth />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            {customerType?.rank === 2 ? (
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  type="number"
                  inputProps={{ max: 10 }}
                  onWheel={(e) => e.target.blur()}
                  label="SD Commission Percent"
                  fullWidth
                  value={commissionPercent}
                  onChange={(e) => setCommissionPercent(e.target.value)}
                />
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <TextField
                size="small"
                type="text"
                label="Description"
                fullWidth
                value={description}
                multiline
                rows={3}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
          </Grid>

          {/* -------contact Info Start---------- */}
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12}>
              <HeadingTitle variant={1}>Contact Info</HeadingTitle>
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                type="text"
                label="Contact"
                fullWidth
                value={contact}
                onChange={(e) => setContact(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                type="text"
                label="Personal Contact"
                fullWidth
                onWheel={(e) => e.target.blur()}
                value={personalContact}
                onChange={(e) => setPersonalContact(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">
                  Social Media
                </InputLabel>
                <Select
                  value={socialMedia}
                  label="Social Media"
                  onChange={(e) => setSocialMedia(e.target.value)}>
                  {socialinfo.map((info, index) => (
                    <MenuItem key={index} value={info}>
                      {info}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                type="email"
                label="Email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
          </Grid>
        </>
      )}

      <Stack direction="row" gap={2} justifyContent="flex-end" pt={3}>
        <Button
          variant="contained"
          color="error"
          sx={{ px: 3 }}
          onClick={() => navigate('/customers')}>
          Cancel
        </Button>
        <Button
          disabled={isDateError1 || isDateError2}
          variant="contained"
          sx={{ px: 3 }}
          onClick={handleSubmit}>
          Save
        </Button>
      </Stack>
    </Box>
  );
}
